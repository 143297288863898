<template>
  <section id="dashboard-ecommerce">
    <b-row>
      <b-col>
        <h1>Manage Add/Edit Service Request</h1>
      </b-col>
      <b-col class="text-right">
        <b-button
          v-if="+$route.query.id"
          :variant="openSection ? 'primary' : 'outline-primary'"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle mr-2"
          @click="openSection = !openSection"
        >
          <feather-icon icon="MessageCircleIcon" size="16" />
        </b-button>
        <b-button
          v-b-modal.modal-manage
          v-if="+$route.query.id"
          variant="danger"
          style="margin-bottom: 7px"
          class="btn-icon rounded-circle"
        >
          <feather-icon icon="Trash2Icon" size="16" />
        </b-button>

        <Modal modalId="modal-manage" @onChange="(v) => onDelete()" />
      </b-col>
    </b-row>

    <b-row>
      <b-col>
        <validation-observer ref="serviceRequestForm" #default="{ invalid }">
          <b-card>
            <b-tabs v-model="activeTab" fill>
              <b-tab title="Information">
                <b-form @submit.prevent="onSubmit">
                  <b-row>
                    <b-col cols="12" :md="openSection ? '9' : '12'">
                      <b-row>
                        <b-col>
                          <small v-if="+$route.query.id">
                            last edited: {{ formData.preview_updated_at }}
                          </small>
                          <h2>Sevice Request Information</h2>
                        </b-col>

                        <b-col class="text-right" v-if="getRole">
                          <ContractsSearch
                            class="mr-1"
                            :company-id="formData.company_id"
                            v-if="formData.company_id"
                            @on-select="(v) => onSelectContract(v)"
                          />
                          <CompanySearch
                            @on-select="(v) => onSelectCompany(v)"
                          />
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Ticket No."
                            label-for="v-ticket-no"
                          >
                            <b-form-input
                              disabled
                              v-model="formData.ticket_no"
                              id="v-ticket-no"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                          <b-form-group label="Company" label-for="v-company">
                            <b-form-input
                              maxlength="60"
                              v-model="formData.company_name"
                              id="v-company"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group
                            label="Contract No."
                            label-for="v-contract_no"
                          >
                            <b-form-input
                              list="contractLists"
                              v-model="formData.contract_refnum"
                              id="v-contract_no"
                              autocomplete="off"
                            />

                            <datalist id="contractLists">
                              <option
                                v-for="(contract, index) in contracts"
                                :key="index"
                                :value="contract.value"
                              >
                                {{ contract.text }}
                              </option>
                            </datalist>
                            <!-- <b-form-select
                              id="v-contract_no"
                              v-model="formData.contract_refnum"
                              :options="contracts"
                              @change="onSelectContract($event)"
                            /> -->
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                          <b-form-group label="PO No." label-for="v-po_no">
                            <b-form-input
                              v-model="formData.po_no"
                              id="v-po_no"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Processed By"
                            label-for="v-assigned_to"
                          >
                            <v-select
                              :disabled="!isAdmin"
                              id="v-assigned_to"
                              v-model.number="formData.assigned_to"
                              :options="users"
                              :reduce="(users) => users.value"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Service Type"
                            label-for="v-service_type"
                          >
                            <b-form-select
                              id="v-service_type"
                              v-model="formData.service_type"
                              :options="[
                                {
                                  text: 'Preventive Maintenance',
                                  value: 'preventive maintenance',
                                },
                                { text: 'Project', value: 'project' },
                                { text: 'Maintenance', value: 'maintenance' },
                                { text: 'Chargeable', value: 'chargeable' },
                              ]"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Service Status"
                            label-for="v-service_status"
                          >
                            <b-form-select v-model="formData.service_status">
                              <b-form-select-option
                                :disabled="
                                  !isAdmin &&
                                  item !== 'New' &&
                                  item !== 'Canceled'
                                "
                                :class="{
                                  'text-muted':
                                    !isAdmin &&
                                    item !== 'New' &&
                                    item !== 'Canceled',
                                }"
                                :value="item"
                                v-for="(item, index) in [
                                  'Potential',
                                  'Missed',
                                  'Aborted',
                                  'New',
                                  'Processing',
                                  'Completed',
                                  'Canceled',
                                ]"
                                :key="index"
                              >
                                {{ item }}
                              </b-form-select-option>
                            </b-form-select>
                            <!-- <v-select
                              id="v-service_status"
                              v-model="formData.service_status"
                              :options="[
                                'Potential',
                                'Missed',
                                'Aborted',
                                'New',
                                'Processing',
                                'Completed',
                                'Canceled',
                              ]"
                            /> -->
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Priority"
                            label-for="v-urgency_level"
                          >
                            <v-select
                              v-model="formData.urgency_level"
                              :options="['Critical', 'High', 'Medium', 'Low']"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Report Date"
                            label-for="v-reported_date"
                          >
                            <b-form-input
                              disabled
                              id="v-reported_date"
                              v-model="formData.preview_reported_date"
                              type="datetime-local"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="3">
                          <b-form-group
                            label="Job Request Date"
                            label-for="v-job_request"
                          >
                            <b-form-input
                              id="v-preview_job_request_date"
                              v-model="formData.preview_job_request_date"
                              type="date"
                              class="form-control"
                            />
                          </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                          <b-form-group label="Status" label-for="v-status">
                            <b-form-select
                              v-model="formData.status"
                              id="v-status"
                              :options="statusOptions"
                            >
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <b-form-group
                            label="Problem Description"
                            label-for="v-problem_description"
                          >
                            <b-form-textarea
                              id="v-problem_description"
                              v-model="formData.problem_description"
                              rows="4"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col cols="6">
                          <h2>Job Site</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <span v-if="!isHidingForm">
                            <CompanyAddressSearch
                              v-if="formData.company_id"
                              :company_id="formData.company_id"
                              @on-select="(v) => onSelectCompanyAddress(v)"
                            />
                          </span>

                          <feather-icon
                            @click="isHidingForm = !isHidingForm"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingForm,
                              arrow_up: !isHidingForm,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingForm">
                        <b-row>
                          <b-col>
                            <b-form-group
                              label="Site/Branch/Outlet Name"
                              label-for="v-site_id"
                            >
                              <b-form-input
                                maxlength="120"
                                v-model="formData.site_id"
                                id="v-site_id"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Building/Block/House No. and Street Name"
                              label-for="v-addr1"
                            >
                              <b-input-group class="input-group-merge">
                                <b-form-input
                                  maxlength="120"
                                  v-model="formData.addr1"
                                  id="v-addr1"
                                />
                                <b-input-group-append
                                  is-text
                                  @click="isShowingMap = !isShowingMap"
                                >
                                  <feather-icon class="pin" icon="MapPinIcon" />
                                </b-input-group-append>
                              </b-input-group>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Unit No. and Building Name"
                              label-for="v-addr2"
                            >
                              <b-form-input
                                maxlength="120"
                                v-model="formData.addr2"
                                id="v-addr2"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group label="Country" label-for="v-country">
                              <v-select
                                v-model="formData.country"
                                :options="
                                  $store.getters['hbaseGeneral/getAllCountries']
                                "
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Postal Code"
                              label-for="v-postal"
                            >
                              <b-form-input
                                maxlength="60"
                                v-model="formData.postal"
                                id="v-postal"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <ModalMap
                              :showMap="isShowingMap"
                              @onChangePlace="(v) => getPlace(v)"
                            />
                          </b-col>
                        </b-row>
                      </span>

                      <b-row>
                        <b-col cols="6">
                          <h2>Contact Information</h2>
                        </b-col>

                        <b-col cols="6" class="text-right">
                          <feather-icon
                            @click="isHidingFormContact = !isHidingFormContact"
                            class="pin isDisplaying"
                            :class="{
                              arrow_down: isHidingFormContact,
                              arrow_up: !isHidingFormContact,
                            }"
                            icon="ChevronUpIcon"
                            size="36"
                          />
                        </b-col>
                      </b-row>

                      <span v-if="!isHidingFormContact">
                        <b-row>
                          <b-col cols="12" md="3">
                            <b-form-group
                              label="Reported By"
                              label-for="v-issued_by"
                            >
                              <b-form-input
                                v-model="formData.issued_by"
                                id="v-issued_by"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="3">
                            <b-form-group
                              label="Contact Person"
                              label-for="v-poc"
                            >
                              <b-form-input v-model="formData.poc" id="v-poc" />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="3">
                            <b-form-group label="Mobile" label-for="v-mobile">
                              <b-form-input
                                v-model="formData.mobile"
                                id="v-mobile"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="3">
                            <b-form-group label-for="v-email">
                              <template v-slot:label>
                                Email <small class="text-danger">*</small>
                              </template>
                              <validation-provider
                                #default="{ errors }"
                                name="Email"
                                rules="required|email"
                              >
                                <b-form-input
                                  maxlength="60"
                                  v-model="formData.email"
                                  :state="errors.length > 0 ? false : null"
                                  type="email"
                                  placeholder="Email"
                                />
                                <small class="text-danger">
                                  {{ errors[0] }}
                                </small>
                              </validation-provider>
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </span>

                      <b-row>
                        <b-col>
                          <h2>Item(s) for Servicing</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobRequestDetails
                              :data-source="dataSourceDetails"
                            />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <b-row>
                        <b-col>
                          <h2>Customer's (Problem) Photos</h2>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <JobRequestImages :data-source="dataSourceImages" />
                          </b-form-group>
                        </b-col>
                      </b-row>

                      <span v-if="formData.job_id || isAdmin">
                        <b-row>
                          <b-col>
                            <h2>Job Assignment Crew</h2>
                          </b-col>
                        </b-row>
                        <b-row>
                          <b-col cols="12" md="6">
                            <b-form-group label-for="v-job">
                              <template v-slot:label> Select Job </template>
                              <v-select
                                id="v-job"
                                label="name"
                                v-model="formData.job_id"
                                :options="jobs"
                                :reduce="(jobs) => +jobs.id"
                                @option:selected="onSelectJob($event)"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="2">
                            <b-form-group
                              label="Job Type"
                              label-for="v-job_type"
                            >
                              <v-select
                                id="v-job_type"
                                v-model="formData.job_type"
                                :options="jobTypes"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" :md="getRole ? '2' : '4'">
                            <b-form-group
                              label="Service Location"
                              label-for="v-service_location"
                            >
                              <b-form-select
                                id="v-service_location"
                                v-model="formData.service_location"
                                :options="[
                                  { text: 'On-Site', value: 'O' },
                                  { text: 'Remote', value: 'R' },
                                ]"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="2" v-if="getRole">
                            <b-form-group
                              label="Standard Price"
                              label-for="v-price"
                            >
                              <b-form-input
                                class="text-right"
                                v-model="formData.standard_price"
                                @change="convertToDecimal"
                                @input="incorrectDecimal"
                                @keypress="isNumber"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12" md="3">
                            <b-form-group
                              label="Job Expected Start Time"
                              label-for="v-job_eta"
                            >
                              <b-form-input
                                @change="loadAvailableTechnicians()"
                                v-model="preview_job_eta"
                                id="v-job_eta"
                                type="datetime-local"
                                class="form-control"
                              />
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="3">
                            <b-form-group
                              label="Job Expected End Time"
                              label-for="v-job_etd"
                            >
                              <b-form-input
                                @change="loadAvailableTechnicians()"
                                v-model="formData.preview_job_etd"
                                id="v-job_etd"
                                type="datetime-local"
                                :class="{ 'border-danger': errDateRange }"
                                class="form-control"
                              />
                              <small class="text-danger" v-if="errDateRange">
                                <strong>Job Expected End Time</strong> must not
                                be less than
                                <strong>Job Expected Start Time</strong>
                              </small>
                            </b-form-group>
                          </b-col>
                          <b-col cols="12" md="6">
                            <b-form-group
                              label="Engineer Assigned"
                              label-for="v-technician_id"
                            >
                              <v-select
                                id="v-technician_id"
                                v-model.number="formData.technician_id"
                                :options="users"
                                :reduce="(users) => users.value"
                                @option:selected="onSelectTechnician($event)"
                              >
                                <template #option="{ label, has_jobs }">
                                  <div
                                    :class="has_jobs > 0 ? 'text-danger' : ''"
                                  >
                                    {{ label }}
                                  </div>
                                </template>
                              </v-select>
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row v-if="isShowingCalendar">
                          <b-col>
                            <b-form-group
                              label="Crew Calendar"
                              label-for="v-calendar"
                            >
                              <CrewCalendar
                                id="v-calendar"
                                :technician="selectedTechnician"
                                :current-date="currentDate"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col>
                            <b-form-group label="Description">
                              <quill-editor
                                ref="quillEditorRef"
                                v-model="content"
                                id="v-job_description"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="6">
                            <h2>Other Information</h2>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <b-form-group label="GST" label-for="v-gst">
                              <v-select
                                id="v-gst"
                                v-model="formData.gst"
                                :options="gsts"
                                :reduce="(gsts) => gsts.value"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>

                        <b-row>
                          <b-col cols="12">
                            <b-form-group
                              label="Instructions/Remarks"
                              label-for="v-remarks"
                            >
                              <b-form-textarea
                                v-model="formData.remarks"
                                id="v-remarks"
                                rows="4"
                              />
                            </b-form-group>
                          </b-col>
                        </b-row>
                      </span>
                    </b-col>
                  </b-row>

                  <b-row>
                    <b-col class="text-right">
                      <b-button
                        type="button"
                        class="mr-1"
                        variant="outline-danger"
                        @click="goBack"
                      >
                        Back
                      </b-button>
                      <b-button
                        v-if="unAvailable"
                        :disabled="invalid || isLoading"
                        @click="isShowingUnavailable = true"
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>
                      <b-button
                        v-if="!unAvailable"
                        :disabled="invalid || isLoading"
                        v-b-modal.tab-information
                        type="button"
                        variant="primary"
                      >
                        {{ onUpdate }}
                      </b-button>

                      <Modal
                        modalId="tab-information"
                        @onChange="(v) => onSubmit()"
                      />
                    </b-col>
                  </b-row>
                </b-form>
              </b-tab>
              <span v-if="$route.query.id">
                <b-tab title="Logs">
                  <Logs module="servicerequest" />
                </b-tab>
                <b-tab title="Comments">
                  <Comments module="servicerequest" />
                </b-tab>
              </span>
            </b-tabs>
          </b-card>
        </validation-observer>
      </b-col>
    </b-row>

    <b-modal v-model="isShowingUnavailable" centered hide-header>
      <b-card-text class="text-center">
        <h3>Technician Unavailble</h3>
        Are you sure you want to proceed with
        <span class="text-danger">overlap time and/or competency not met</span>?
      </b-card-text>

      <template #modal-footer="{ close }">
        <div>
          <b-button
            variant="danger"
            class="bg-darken-4 mr-1"
            size="sm"
            @click="close()"
          >
            Cancel
          </b-button>
          <b-button
            variant="primary"
            class="bg-darken-4"
            size="sm"
            @click="
              close();
              onSubmit();
            "
          >
            Accept
          </b-button>
        </div>
      </template>
    </b-modal>
  </section>
</template>

<script>
import companiesApi from '@api/companies';
import contractsApi from '@api/contract_headers';
import jobRequestApi from '@api/jobrequest_headers';
import usersApi from '@api/users';
import jobsApi from '@api/jobs';
import gstsApi from '@api/gsts';
import statusesApi from '@api/statuses';
import { queryParameters } from '@/schema';
import { required, email } from '@validations';
// @todo change from utils classes to global-function
import {
  nowToLocaleDateString,
  unixToDisplayDateTime,
  addToTime,
  UtilsDate,
} from '@/utils/classes/dates';
import JobRequestDetails from './components/JobRequestDetails';
import JobRequestImages from './components/JobRequestImages';
import JobRequestComments from './components/JobRequestComments';
import JobRequestLogs from './components/JobRequestLogs';
import CrewCalendar from './components/CrewCalendar';
import { getUserData } from '@/auth/utils';

export default {
  name: 'ServiceRequestManage',
  components: {
    JobRequestDetails,
    JobRequestImages,
    JobRequestComments,
    JobRequestLogs,
    CrewCalendar,
  },
  data: () => ({
    required,
    email,
    success: false,
    openSection: false,
    activeTab: 0,
    errors: {},
    isShowingMap: false,
    isHidingForm: false,
    isHidingFormContact: false,
    isHidingFormRemarks: false,
    isLoading: false,
    isShowingCalendar: true,
    isShowingUnavailable: false,
    jobTypes: [
      'Configure',
      'Dismantle',
      'Inspect',
      'Install',
      'Renew',
      'Repair',
      'Replace',
      'Service',
      'Survey Job Type',
    ],
    dataSourceDetails: [],
    dataSourceImages: [],
    users: [{ label: 'N/A', value: 0 }],
    gsts: [],
    contracts: [],
    jobs: [],
    statusOptions: [{ text: 'New', value: 'n' }],
    content: '',
    preview_job_eta: nowToLocaleDateString,
    unAvailable: false,
    formData: {
      id: null,
      ticket_no: null,
      assigned_to: null,
      company_id: null,
      company_name: null,
      job_id: null,
      job_type: null,
      job_name: null,
      job_status: null,
      job_request_date: null,
      job_eta: null,
      job_etd: null,
      job_description: null,
      standard_price: null,
      standard_time: null,
      technician_id: null,
      site_id: null,
      addr1: null,
      addr2: null,
      postal: null,
      country: null,
      region: null,
      zone: null,
      latitude: null,
      longitude: null,
      refnum: null,
      service_location: 'O',
      service_type: null,
      service_status: 'New',
      request_refnum: null,
      problem_description: null,
      reported_date: null,
      urgency_level: 'High',
      poc: null,
      mobile: null,
      tel: null,
      fax: null,
      email: null,
      designation: null,
      remarks: null,
      contract_type: null,
      contract_refnum: null,
      po_no: null,
      gst: null,
      issued_by: null,
      status: 'new',

      hrs: 0,
      mins: 0,

      details: [],
      images: [],

      preview_job_etd: nowToLocaleDateString,
      preview_reported_date: nowToLocaleDateString,
      preview_job_request_date: nowToLocaleDateString.split('T')[0],
    },
    selectedTechnician: null,
    currentDate: new Date(),
    errDateRange: false,
  }),
  watch: {
    preview_job_eta() {
      const { preview_job_request_date } = this.formData;
      const params_h24 = {
        hour: '2-digit',
        minute: '2-digit',
        hourCycle: 'h24',
      };

      const timeNow = new Date().toLocaleDateString('en-GB', params_h24);
      const timeNowSplit = timeNow.split(', ')[1];

      return `${preview_job_request_date}T${timeNowSplit}`;
    },
  },
  computed: {
    onUpdate: {
      get() {
        return this.formData.id ? 'Update' : 'Create';
      },
    },
    getRole: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        const roles = ['admin', 'qcsemployee'];
        return roles.includes(role);
      },
    },
    isAdmin: {
      get() {
        const userData = getUserData();
        const { role } = userData;

        const roles = ['admin', 'qcsemployee'];
        return roles.includes(role);
      },
    },
  },
  mounted() {
    this.formData.preview_reported_date = this.inputDateTimeToLocale();
    this.preview_job_eta = this.defaultDateTime();
    this.formData.preview_job_etd = this.defaultDateTime();

    this.loadSystemCode();
    this.loadCompany();
    this.loadAvailableTechnicians();
    this.loadJobs();
    this.loadGSTS();

    if (+this.$route.query.id) {
      this.loadJobRequest();
    } else {
      const { fullName } = getUserData();
      this.formData.issued_by = fullName;
    }
  },
  methods: {
    loadJobRequest() {
      jobRequestApi
        .get(this.$route.query.id)
        .then(({ data }) => {
          if (data != null) {
            const toDisplayDate = (date) => {
              return new UtilsDate(date).unixToInputDateTime();
            };

            const {
              updated_at,
              reported_date,
              job_request_date,
              job_description,
              job_eta,
              job_etd,
              standard_time,
              standard_price,
            } = data;

            const { quill } = this.$refs.quillEditorRef;
            if (quill) {
              quill.root.innerHTML = job_description;
            }

            data.standard_price = standard_price.toFixed(2);
            data.preview_updated_at = unixToDisplayDateTime(updated_at);

            if (reported_date) {
              data.preview_reported_date = toDisplayDate(reported_date);
            }

            if (job_request_date) {
              const [splitDisplay] = toDisplayDate(job_request_date).split('T');
              data.preview_job_request_date = splitDisplay;
            }

            if (job_eta) {
              this.preview_job_eta = toDisplayDate(job_eta);
            }

            if (job_etd) {
              data.preview_job_etd = toDisplayDate(job_etd);
            }

            data.hrs = Math.floor(standard_time / 60);
            data.mins = standard_time % 60;

            if (data.jobrequest_details.data) {
              this.dataSourceDetails = data.jobrequest_details.data;
            }

            if (data.jobrequest_images.data) {
              this.dataSourceImages = data.jobrequest_images.data;
            }

            this.loadContracts(data.company_id);
            this.formData = data;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadSystemCode() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      statusesApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            const items = [];

            for (const item of data) {
              const { code: value, name: text, selections } = item;
              items.push({ value, text, selections });
            }

            this.statuses = items;

            const coystatus = items.find((x) => {
              return x.value === 'sr:status';
            });
            if (coystatus) {
              const { selections } = coystatus;
              this.statusOptions.push(...selections);
            }
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadContracts(company_id) {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
        filterExt: `company_id=${company_id}`,
      };

      contractsApi
        .list(params)
        .then(({ data }) => {
          if (data != null) {
            const items = [];
            for (const item of data) {
              const { refnum, contract_type } = item;
              items.push({ value: refnum, text: refnum, contract_type });
            }

            this.contracts = items;
          }
        })
        .catch((err) => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadAvailableTechnicians() {
      const etd = new Date(this.formData.preview_job_etd);
      const eta = new Date(this.preview_job_eta);

      this.isLoading = eta > etd;
      this.errDateRange = eta > etd;

      this.users = [{ label: 'N/A', value: 0 }];

      const replace = (data) => {
        let date = data.replace('T', ' ');
        date += ':00.000 +0800';

        return date;
      };

      this.currentDate = new Date(this.preview_job_eta);

      const job_eta = replace(this.preview_job_eta);
      const job_etd = replace(this.formData.preview_job_etd);

      const params = {
        filterExt: `from_to=${job_eta}|${job_etd}`,
      };

      usersApi
        .getAvailableTechnicians(params)
        .then(({ data }) => {
          for (const item of data) {
            const { id, first_name, last_name, username, has_jobs } = item;

            let fullName = first_name || username;
            if (first_name && last_name) {
              fullName += ` ${last_name}`;
            }

            this.users.push({
              label: fullName,
              value: parseInt(id),
              has_jobs,
              item,
            });
          }
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadJobs() {
      const params = {
        sort: queryParameters.sort,
        page: { size: 1000 },
        status: queryParameters.status,
      };

      jobsApi
        .list(params)
        .then(({ data }) => {
          this.jobs = data;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadGSTS() {
      const params = {
        sort: queryParameters.sort,
        page: queryParameters.page,
        status: queryParameters.status,
      };

      gstsApi
        .list(params)
        .then(({ data }) => {
          const items = [];

          for (let i = 0; i < data.length; i++) {
            const item = data[i];
            const { name, description, percent } = item;

            items.push({
              label: `${description} - ${percent}%`,
              value: name,
              item,
            });
          }

          this.gsts = items;
        })
        .catch(() => {
          //
        })
        .finally(() => {
          //
        });
    },
    loadCompany() {
      const userData = getUserData();
      const { companyId } = userData;

      if (companyId) {
        const params = {
          filterExt: `id=${companyId}`,
        };

        companiesApi
          .list(params)
          .then(({ data }) => {
            if (data.length > 0) {
              const [item] = data;

              if (item) {
                this.onSelectCompany(item);
              }
            }
          })
          .catch((err) => {
            //
          })
          .finally(() => {
            //
          });
      }
    },
    onSubmit() {
      this.isLoading = true;
      const error = () => {
        window.scrollTo(0, 0);
        this.toastConfig('', 'error');
        this.success = false;
        this.errors = err;
      };

      const replace = (data) => {
        if (data && data.match(/[0-9.-]+/g)) {
          return Number(data.replace(/[^0-9.-]+/g, ''));
        }
      };

      const toUnix = (value) => {
        if (!value) {
          return new UtilsDate().dateToUnix();
        }

        return new UtilsDate(value).dateToUnix();
      };

      const data = this.formData;

      data.reported_date = toUnix(data.preview_reported_date);
      data.job_request_date = toUnix(data.preview_job_request_date);
      data.job_eta = toUnix(this.preview_job_eta);
      data.job_etd = toUnix(data.preview_job_etd);
      data.contract_type = data.service_type;
      data.job_description = this.content;
      data.standard_price = replace(data.standard_price);

      const getDetails = (dataSource, opt = { addOn: false }) => {
        const details = [];
        for (let i = 0; i < dataSource.length; i++) {
          const detail = dataSource[i];
          if (opt.addOn) {
            if (typeof detail.id !== 'number') {
              detail.reference_id = detail.id;
            }
          }

          details.push(detail);
        }

        return details;
      };

      data.details = getDetails(this.dataSourceDetails);
      data.images = getDetails(this.dataSourceImages, { addOn: true });

      let app = null;

      if (!data.id) {
        app = jobRequestApi.add(data);
      } else {
        app = jobRequestApi.update(data);
      }

      if (app) {
        app
          .then(({ data }) => {
            this.toastConfig();
            this.isLoading = false;
            const url = `/pages/services-tools/service-request/view?id=${data.id}`;
            this.$router.push(url);
          })
          .catch((err) => {
            error();
          })
          .finally(() => {
            //
          });
      }
    },
    goBack() {
      this.$router.push(`/pages/services-tools/service-request`);
    },
    onSelectCompany(e) {
      if (e) {
        const data = this.formData;

        const { company_contacts, company_locations } = e;

        if (company_locations && company_locations.data) {
          const locations = company_locations.data;

          if (locations.length > 0) {
            const [location] = locations;
            const {
              site_id,
              addr1,
              addr2,
              country,
              postal,
              latitude,
              longitude,
            } = location;

            data.site_id = site_id;
            data.addr1 = addr1;
            data.addr2 = addr2;
            data.country = country;
            data.postal = postal;
            data.latitude = parseFloat(latitude || 0);
            data.longitude = parseFloat(longitude || 0);
          } else {
            data.site_id = null;
            data.addr1 = null;
            data.addr2 = null;
            data.country = 'Singapore';
            data.postal = null;
            data.latitude = 0;
            data.longitude = 0;
          }
        }

        if (company_contacts && company_contacts.data) {
          const contacts = company_contacts.data;

          if (contacts.length > 0) {
            const [contact] = contacts;
            const { first_name, last_name, designation, email, tel, mobile } =
              contact;

            data.poc = `${first_name} ${last_name}`;
            data.designation = designation;
            data.email = email;
            data.tel = tel;
            data.mobile = mobile;
          } else {
            data.poc = null;
            data.designation = null;
            data.email = null;
            data.tel = null;
            data.mobile = null;
          }
        }

        data.company_id = +e.id;
        data.company = e.company_name;
        data.company_name = e.company_name;
        data.gst = e.gst;
        data.contract_refnum = null;

        this.formData = data;
      }
    },
    getPlace(place) {
      const data = this.formData;
      const { name, country, postal, lat, lng } = place;

      data.addr1 = name;
      data.country = country || 'Singapore';
      data.postal = postal;
      data.latitude = parseFloat(lat || 0);
      data.longitude = parseFloat(lng || 0);
    },
    onSelectCompanyAddress(item) {
      if (item) {
        const data = this.formData;

        data.site_id = item.site_id;
        data.addr1 = item.addr1;
        data.addr2 = item.addr2;
        data.country = item.country || 'Singapore';
        data.postal = item.postal;
      }
    },
    onSelectJob(data) {
      this.formData.job_name = data.name;
      this.formData.job_type = data.job_type;
      this.formData.standard_price = this.moneyFormat(
        data.standard_price
      ).replace('$', '');
      this.formData.standard_time = data.standard_time;
      this.formData.hrs = Math.floor(data.standard_time / 60);
      this.formData.mins = data.standard_time % 60;
      this.formData.job_description = data.notes;

      const { quill } = this.$refs.quillEditorRef;
      if (quill) {
        quill.root.innerHTML = data.notes;
      }

      const toUnix = (value) => {
        if (!value) {
          return new UtilsDate().dateToUnix();
        }

        return new UtilsDate(value).dateToUnix();
      };

      const toLocaleString = (date) => {
        return new UtilsDate(date).unixToInputDateTime();
      };

      const job_eta = toUnix(this.preview_job_eta);

      const time = this.formData.hrs * 60 + this.formData.mins;
      this.formData.preview_job_etd = toLocaleString(addToTime(job_eta, time));

      this.formData = Object.assign({}, this.formData);
      this.loadAvailableTechnicians();
    },
    onSelectContract(data) {
      if (data) {
        const { refnum, contract_type } = data;
        this.formData.contract_refnum = refnum;
        this.formData.service_type = contract_type;
      }
    },
    onSelectTechnician(data) {
      this.unAvailable = false;
      this.selectedTechnician = {
        id: 0,
        display_name: 'N/A',
      };

      if (data && data.item) {
        const { id, first_name, last_name, has_jobs } = data.item;

        if (has_jobs > 0) {
          this.unAvailable = true;
        }

        this.selectedTechnician = {
          id: parseInt(id),
          display_name: `${first_name} ${last_name}`,
        };
      }
    },
    convertToDecimal() {
      const { standard_price } = this.formData;

      if (standard_price != '' && this.correctMoneyFormat(standard_price)) {
        const toDecimal = this.moneyFormat(standard_price).replace('$', '');

        const valueIsNan = isNaN(toDecimal.replaceAll(',', ''));
        this.formData.standard_price = valueIsNan ? '0.00' : toDecimal;

        return;
      }

      this.formData.standard_price = 0;
    },
    incorrectDecimal() {
      let { standard_price } = this.formData;
      this.correctDecimalFormat = this.correctMoneyFormat(standard_price);
    },
    isNumber(event) {
      if (isNaN(event.key) && event.key !== '.' && event.key !== 'Backspace') {
        event.preventDefault();
      }

      if (event.code === 'Space') {
        event.preventDefault();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.pin:hover {
  cursor: pointer;
}

.isDisplaying.arrow_down {
  transform: rotate(180deg);
  transition: transform 0.25s linear;
}

.isDisplaying.arrow_up {
  transform: rotate(360deg);
  transition: transform 0.25s linear;
}
</style>
