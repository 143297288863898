var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',[_c('DxScheduler',{attrs:{"time-zone":"Asia/Singapore","show-all-day-panel":false,"data-source":_vm.dataSource,"current-date":_vm.currentDate,"views":_vm.views,"groups":_vm.groups,"height":500,"start-day-hour":0,"current-view":"day","data-cell-template":"dataCellTemplate","date-cell-template":"dateCellTemplate","time-cell-template":"timeCellTemplate","resource-cell-template":"resourceCellTemplate","appointment-template":"appointmentTemplate"},scopedSlots:_vm._u([{key:"resourceCellTemplate",fn:function(ref){
var user = ref.data;
return [_c('b-row',[_c('b-col',[_c('div',{staticStyle:{"margin-bottom":"7px"}},[_vm._v(" "+_vm._s(user.data.display_name)+" ")])])],1)]}},{key:"dataCellTemplate",fn:function(ref){
var cellData = ref.data;
return [_vm._v(" "+_vm._s(cellData.text)+" ")]}},{key:"dateCellTemplate",fn:function(ref){
var cellData = ref.data;
return [_c('div',{},[_vm._v(" "+_vm._s(cellData.text)+" ")])]}},{key:"timeCellTemplate",fn:function(ref){
var cellData = ref.data;
return [_c('div',{},[_vm._v(" "+_vm._s(cellData.text)+" ")])]}},{key:"appointmentTemplate",fn:function(ref){
var formData = ref.data.appointmentData;
return [_c('div',{},[_c('div',{},[_c('strong',[_vm._v(_vm._s(formData.ticket_no))]),_vm._v(" ("+_vm._s(formData.job_type)+") ")]),_c('div',{},[_vm._v(" "+_vm._s(formData.company_name)+" - "+_vm._s(formData.address)+" ")]),_c('div',{},[_c('small',{staticClass:"font-italic"},[_vm._v(" "+_vm._s(formData.preview_time_job_eta)+" - "+_vm._s(formData.preview_time_job_etd)+" ")])])])]}}])},[_c('DxResource',{attrs:{"use-color-as-default":true,"data-source":_vm.jobs,"field-expr":"reference_id"}}),_c('DxResource',{attrs:{"data-source":_vm.users,"allow-multiple":false,"label":"Employee","field-expr":"job_assigned_to_1"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }